import React, {FunctionComponent} from 'react';
import NavigationBar from '../../components/molecules/NavigationBar/NavigationBar';
import styles from './HotTubGrid.module.sass';
import SpaData from '../../data/HotTubData';
import HotTubGridItem from "../../components/molecules/HotTubGridItem/HotTubGridItem";
import Footer from "../../components/molecules/Footer/Footer";
import FadePageTransition from "../../animations/FadePageTransition";
import FadeAndSlideUp from "../../animations/FadeAndSlideUp";
import ScrollToTop from "../../utils/ScrollToTop";
import {AMERICAN_WHIRLPOOL, VIKING_SPAS, VITA_SPAS} from "../../data/BrandsData";

const HotTubGrid: FunctionComponent = (props: any) => {

    return (
        <FadePageTransition>
            <ScrollToTop/>
            <div className={styles.spasGrid}>
                <NavigationBar background="hotTubs"/>
                <FadeAndSlideUp duration={0.3}>
                    <div className={styles.grid}>
                        {
                            props.match.params && props.match.params.brand && props.match.params.brand === 'American-Whirlpool'
                                ? SpaData.filter((spa) => spa.brand === AMERICAN_WHIRLPOOL).sort((a, b) => (a.price > b.price) ? 1 : -1).map((item, index) =>
                                    <HotTubGridItem hotTub={item} key={index}/>)
                                : null
                        }
                        {
                            props.match.params && props.match.params.brand && props.match.params.brand === 'Vita-Spas'
                                ? SpaData.filter((spa) => spa.brand === VITA_SPAS).sort((a, b) => (a.price > b.price) ? 1 : -1).map((item, index) =>
                                    <HotTubGridItem hotTub={item} key={index}/>)
                                : null
                        }
                        {/* {
                            props.match.params && props.match.params.brand && props.match.params.brand === 'Viking-Spas'
                                ? SpaData.filter((spa) => spa.brand === VIKING_SPAS).sort((a, b) => (a.price > b.price) ? 1 : -1).map((item, index) =>
                                    <HotTubGridItem hotTub={item} key={index}/>)
                                : null
                        } */}
                        {
                            props.match.params && !props.match.params.brand
                                ? SpaData.sort((a, b) => (a.price > b.price) ? 1 : -1).map((item, index) =>
                                    <HotTubGridItem hotTub={item} key={index}/>)
                                : null
                        }
                    </div>
                </FadeAndSlideUp>
                <Footer isDark={true}/>
            </div>
        </FadePageTransition>
    )
}

export default HotTubGrid;